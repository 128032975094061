import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import Img from "gatsby-image";
import { Buffer } from "buffer";

import removeIcon from "../../assets/x-square.svg";
import Container from "../../components/Container/container";
import CTA from "../../components/CTA/cta";
import deleteLineItem from "../../actions/delete-item-checkout";
import navigateToCheckout from "../../utils/navigate-checkout";
import "./cart.scss";
import { useTrackCart } from "../../hooks/useTrackCart";
import { OneTreePlanted } from "./tree";
import {
	ShippingProtection,
	ShippingProtectionInfo,
} from "./shipping-protection";

const Cart = (props) => {
	const domain = process.env.GATSBY_MYSHOPIFY_URL || "default";
	const [pendingDelete, setPendingDelete] = useState(null);

	const products = props.data.allShopifyProduct.edges;
	products.forEach(({ node }) => {
		if (node.id.includes("__")) {
			node.id = node.id.split("__").pop();
		}
	});

	const { trackRemoval } = useTrackCart(props?.cart?.lineItems, products);

	const checkoutWithShopify = () => {
		const { cart, queryString } = props;

		// If using upsell.
		const hasUpsell = cart.lineItems.find((item) => {
			return item?.title.includes("Special Offer");
		});

		const hasPhysicalItems = cart.lineItems.find((item) => {
			return (
				!item?.title.includes("Gift Card") &&
				!item.tags.includes("Parts")
			);
		});

		if (hasUpsell || !hasPhysicalItems) {
			navigateToCheckout(cart, queryString);
		} else {
			navigate("/last-chance");
		}

		// If not using upsell.
		// navigateToCheckout(cart, queryString);
	};

	const continueShopping = () => {
		if (props.preOrderAuthorized === "halloween") {
			navigate("/products/halloween");
			return;
		}
		navigate("/products");
	};

	const removeLineItem = (item) => {
		if (
			props.cart &&
			Array.isArray(props.cart.lineItems) &&
			props.cart.lineItems.length === 2 &&
			props.cart.lineItems.find((item) => {
				return item.tags.includes("Special Offer");
			}) &&
			!item.tags.includes("Special Offer")
		) {
			setPendingDelete(item);
		} else {
			deleteLineItem(item, props.cart.id, props.setCart);
		}

		trackRemoval(item);
	};

	const confirmPendingDelete = () => {
		deleteLineItem(pendingDelete, props.cart.id, props.setCart);
		setPendingDelete(null);
	};

	const cancelPendingDelete = () => {
		setPendingDelete(null);
	};

	const renderLineItem = (item) => {
		let variant;
		const itemId = item.variant;
		const product = products.find((prod) => {
			variant = prod.node.variants.find((variant) => {
				const hashedShopifyId = Buffer.from(
					variant?.shopifyId,
					"utf-8",
				).toString("base64");
				return (
					itemId === variant?.shopifyId || itemId === hashedShopifyId
				);
			});
			return variant;
		});

		let img = null;
		const preOrder = item.tags.includes("Pre Order");

		if (variant?.image?.localFile?.childImageSharp?.fluid) {
			img = variant?.image?.localFile?.childImageSharp?.fluid;
		} else if (
			product?.node?.featuredImage?.localFile?.childImageSharp?.fluid
		) {
			img = product.node.featuredImage.localFile.childImageSharp.fluid;
		} else {
			img =
				variant &&
				variant?.image &&
				variant.image.localFile &&
				variant.image.localFile.childImageSharp &&
				variant.image.localFile.childImageSharp.fluid;
		}

		const currencyCode =
			variant?.presentmentPrices?.[0]?.price?.currencyCode;

		return (
			<div className="cart__line-item" key={item.id}>
				<div className="cart__line-left">
					<div className="cart__line-image-wrapper">
						{img ? (
							<Img
								className="cart__line-image"
								fluid={img}
								alt={variant?.title}
							/>
						) : (
							<img
								className="cart__line-image"
								src={img}
								alt={variant?.title}
							/>
						)}
					</div>
					<div className="cart__line-info">
						<div className="cart__line-title">
							{item?.title}{" "}
							{variant?.title === "Upsell" && (
								<span>- SPECIAL OFFER</span>
							)}
						</div>
						{variant?.title !== "Default Title" && (
							<div className="cart__line-description">
								{variant?.title}
							</div>
						)}
						{item?.title === "One Tree Planted" ? (
							<div className="cart__line-description">
								Tearribles will plant a tree for every purchase.
							</div>
						) : null}
						{preOrder && (
							<div
								className="cart__line-description"
								style={{ color: "#26b901", fontWeight: 600 }}
							>
								Pre Order: Expected to ship by Feb 19th
							</div>
						)}
						{item &&
							Array.isArray(item.customAttributes) &&
							item.customAttributes.map(({ key, value }) => {
								return (
									<div
										className="cart__line-description"
										key={key}
									>
										{`${key}: ${value}`}
									</div>
								);
							})}
					</div>
				</div>
				<div className="cart__line-right">
					<div>
						<div>
							{currencyCode === "USD" && "$ "}
							{Number.parseFloat(variant?.price).toFixed(2)}
							{currencyCode === "AUD" && " AUD"}
						</div>
						<div className="cart__line-qty">
							QTY: {item.quantity}
						</div>
					</div>
					<div
						className="cart__remove"
						onClick={() => removeLineItem(item)}
					>
						<img
							className="cart__remove-icon"
							src={removeIcon}
							alt="Remove item from cart."
						/>
					</div>
				</div>
			</div>
		);
	};

	if (!props.cart.lineItems || props.cart.lineItems.length === 0) {
		return (
			<div className="cart__empty">
				<h2 className="cart__title">
					NO ITEMS CURRENTLY
					<br />
					IN YOUR CART
				</h2>
				<CTA title="SEE TEARRIBLES" to="/products" />
			</div>
		);
	}

	return (
		<>
			{pendingDelete && (
				<div className="modal__background">
					<div className="modal__card">
						<h3>Oh no! &nbsp; Hold on</h3>
						<p>
							Removing this item will also remove your{" "}
							<span>Special Offer</span> item.
						</p>
						<p>Are you sure?</p>
						<CTA
							title="Yes, remove items"
							onClick={() => confirmPendingDelete()}
						/>
						<CTA
							title="No, keep items"
							onClick={() => cancelPendingDelete()}
							secondary
						/>
					</div>
				</div>
			)}
			<div className="cart">
				<Container style={{ maxWidth: "720px" }}>
					<div className="cart__top">
						<CTA
							title="CONTINUE SHOPPING"
							prefixIcon="back-arrow"
							styles={{ marginBottom: 0 }}
							inverted
							onClick={() => continueShopping()}
						/>
						<CTA
							title="CHECKOUT NOW"
							styles={{ marginBottom: 0 }}
							onClick={() => checkoutWithShopify()}
						/>
					</div>
					{/* <OneTreePlanted {...props} /> */}
					<h2 className="cart__title">CART</h2>
					<div className="cart__items">
						{props.cart.lineItems &&
							props.cart.lineItems
								.filter((item) => {
									return !item.tags.includes(
										"Shipping Protection",
									);
								})
								.map((item) => {
									return renderLineItem(item);
								})}
					</div>
					<ShippingProtection
						{...props}
						removeLineItem={removeLineItem}
					/>
					<div className="cart__totals">
						<div className="cart__items-subtotal">
							<div>Subtotal</div>
							<div>
								${" "}
								{Number.parseFloat(
									props.cart?.subtotalPrice?.amount,
								).toFixed(2)}
							</div>
						</div>
						<div className="cart__items-total">
							<div>Total</div>
							<div>
								${" "}
								{Number.parseFloat(
									props.cart?.totalPrice?.amount,
								).toFixed(2)}
							</div>
						</div>
					</div>
					<ShippingProtectionInfo />
				</Container>
			</div>
		</>
	);
};

export default Cart;
